import { Typography, Stack } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Page from '#/components/shared/ui/Page';
import Iconify from '#/components/shared/ui/Iconify';

import useAuth from '#/hooks/useAuth';
import useLocales from '#/hooks/useLocales';

const AcceptInvitation = () => {
  const [error, setError] = useState(false);
  const { token } = useParams();
  const { pathname } = useLocation();
  const { translate } = useLocales();

  const isTeamMember = pathname.includes('/partner');

  const { loginFromToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      loginFromToken(token, isTeamMember ? 'partners' : undefined)
        .then(() => {
          navigate('/dashboard/home');
        })
        .catch(() => {
          setError(true);
          setTimeout(() => {
            navigate('/auth/login');
          }, 3000);
        });
    }

    return () => {
      setError(false);
    };
    // eslint-disable-next-line
  }, [token]);

  return (
    <Page title="Invitation">
      <Stack
        sx={{
          py: 12,
          // maxWidth: 400,
          textAlign: 'center',
          margin: 'auto',
          minHeight: '100vh',
          justifyContent: 'center',
        }}
      >
        {error && (
          <Iconify
            icon="bx:error"
            color="error"
            sx={{
              alignSelf: 'center',
            }}
            width="32px"
            height="32px"
          />
        )}
        {!error && (
          <Iconify
            icon="eos-icons:bubble-loading"
            sx={{
              alignSelf: 'center',
            }}
            width="32px"
            height="32px"
          />
        )}
        <Typography>
          {String(
            translate(
              `global.${
                error ? 'acceptingInvitationFail' : 'acceptingInvitation'
              }`
            )
          )}
        </Typography>
      </Stack>
    </Page>
  );
};

export default AcceptInvitation;
